import React, {useState} from 'react'
import { usePostRequest } from '../hooks/requestFront'
import camera             from '../static/img/dms-check/photo_camera_icon.svg'
import {PHONE_CHECK_UPLOAD} from '../urls';
import {isEmpty} from 'lodash'

export default function PhoneCheckCreate({ onCancel, onSuccess, qr_value = '' }) {
    const phoneUploadRequest = usePostRequest({
        url: PHONE_CHECK_UPLOAD
    })
    
    const phoneAuthRequest = usePostRequest({
        data: {
            "username":"front_service",
            "password":"humo_service_123"
        },
        url: '/v1/user/auth/'
    })
    
    const [agree, setAgree] = useState(false)
    const [validated, setValidated] = useState(false)
    const [file, setFile] = useState({})
    const [success, setSuccess] = useState(false)
    
    function setPhoto(e) {
        e.preventDefault()
        e.stopPropagation()
        const newFile = {...file}
        for (const item of e.target.files) {
            const name = item.name
            newFile[name] = item
        }
        setFile(newFile)
    }
    
    async function sendPhoto(e) {
        e.preventDefault()
        e.stopPropagation()
        if (!isEmpty(file)) {
            const auth = await phoneAuthRequest.request()
            //localStorage.setItem('AuthToken', auth.response.data.access)
            localStorage.setItem('AuthToken', auth.response.access)
            
            if (auth.success) {
    
                const data = new FormData()
                Object.values(file).forEach((item) => {
                    data.append('files', item)
                })
                setValidated(true)
                if (agree && Object.keys(file).length > 0) {
                    data.append('policy_number', qr_value)
        
                    const {success, response} = await phoneUploadRequest.request({data})
                    if (success) {
                        setSuccess(true)
                    }
                    if (success && onSuccess) {
                        onSuccess(response)
                    }
                }
            }
        }
    }

    if (success)
        return (
            <div style={{ color: "#eead09", fontSize: '20pt', textAlign: 'center', fontWeight: 'bolder'}}>Фотография отправлена успешно</div>
        )
    
    return (
        <div>
            <section className="load">
                <form action="" className="load__form" onSubmit={sendPhoto}>
                    <p className="load__text">Для активации полиса Вам необходимо загрузить фото экрана телефона, который является
                            объектом страхования:</p>
                    <div style={{ fontSize: '12pt', textAlign: 'left', marginBottom: '20px'}}>
                            {
                                Object.keys(file).map((item) => {
                                    return (<p style={{ marginBottom: '6px' }}>{item}</p>)
                                })
                            }
                    </div>
                    <label className="screen-button">
                            <input type="file" name="photo" style={{ display: 'none' }} onChange={setPhoto} multiple />
                            <img src={camera} alt="Значок фотоаппарата" className="screen-button__icon" width="36px" height="36px" />
                                Загрузить фото
                    </label>
                    <div className="checkbox">
                            <input className="check-input" name="checkit" type="checkbox" id="check"
                                   value={agree} onChange={(e) => setAgree(e.target.checked)} />
                            <label className="check-label" htmlFor="check">
                                Я подтверждаю, что на момент активации полиса целостность экрана телефона не нарушена.
                            </label>
                            { (!agree && validated && Object.keys(file).length > 0) && (
                                <span className="input__error-text input__error-text-left input__error-text--active">
                                    Необходимо подтверждение
                                </span>
                            ) }
                    </div>
                    <button className="load__submit screen-button" type="submit"
                                value="Отправить информацию" disabled={!agree || Object.keys(file).length === 0}>
                            Отправить
                    </button>
                </form>
            </section>
        </div>
    )
}
