import React from 'react'
import { usePostRequest } from '../hooks/request'
import DmsCheckForm from './DmsCheckForm'
import {DMS_CHECK_UPLOAD} from "../urls";

export default function DmsCheckCreate({ onCancel, onSuccess, qr_value = '' }) {
    const dmsCheckCreate = usePostRequest({
        url: DMS_CHECK_UPLOAD.replace('{qr_value}', qr_value)
    })

    async function onSubmit(data) {
        const { success, response } = await dmsCheckCreate.request({ data })
        if (success && onSuccess) {
            onSuccess(response)
        }
    }

    return (
        <div>
            <DmsCheckForm
                onSubmit={onSubmit}
                onCancel={onCancel}
                loading={dmsCheckCreate.loading} />
        </div>
    )
}
