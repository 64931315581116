import React     from 'react'
import whiteLogo from '../../static/img/svg/logo_white.svg'
import message   from '../../static/img/dms-check/Message.svg'
import phone     from '../../static/img/dms-check/phone.svg'
import location  from '../../static/img/dms-check/Location.svg'

const Footer = () => (
    <footer className="footer">
        <div className="container footer__container">
            <img
                src={whiteLogo}
                width="76px"
                height="42px"
                alt="Логотип BIMA Insurance"
                className="footer__logo"
            />
            <a
                href="mailto:mail@bima.tj"
                className="footer__info"
                aria-label="Связаться по электронной почте"
            >
                <img
                    src={message}
                    width="24px"
                    height="24px"
                    className="footer__info-icon"
                    alt="Иконка письма"
                />
                mail@bima.tj
            </a>
            <a
                href="tel:5511"
                className="footer__info"
                aria-label="Позвонить в компанию BIMA Insurance"
            >
                <img
                    src={phone}
                    width="24px"
                    height="24px"
                    className="footer__info-icon"
                    alt="Иконка телефона"
                />
                (44) 601 55 11 / 5511
            </a>
            <div className="footer__geoposition">
                <img
                    src={location}
                    width="24px"
                    height="24px"
                    className="footer__info-icon"
                    alt="Иконка геопозиции"
                />
                <span className="footer__info-text"
                >г. Душанбе, <br/>
            ул. Нусратулло Махсум 74/3</span
                >
            </div>
        </div>
    </footer>
)

export default Footer
