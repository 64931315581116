import React, {useEffect, useState} from 'react'
import { Form, Formik }             from 'formik'
import camera                       from '../static/img/dms-check/photo_camera_icon.svg'
import {isEmpty}                    from 'lodash'

export default function DmsCheckForm({ onSubmit }) {
    const [file, setFile] = useState({})
    
    function onSuccess() {
        if (!isEmpty(file)) {
            const newData = new FormData()
            Object.values(file).forEach((item) => {
                newData.append('files', item)
            })
            onSubmit(newData)
        }
    }
    
    useEffect(() => {
        onSuccess(file, onSubmit)
    }, [file])

    return (
        <Formik onSubmit={() => {}} initialValues={{}}>
            <Form>
                <label className="info__button button" type="button">
                    <img src={camera} width="36px" height="36px" alt="" className="button__icon" />
                    <label>Загрузить чек
                        <input
                            onChange={(e) => {
                                const newFile = {}
                                const name = e.target.files[0].name
                                newFile[name] = e.target.files[0]
                                console.log(newFile)
                                console.log({...file, ...newFile})
                                setFile({ ...file, ...newFile })
                            }}
                            style={{ display: 'none' }}
                            type="file" name="" />
                    </label>
                </label>
                <div className="uploaded-files">Загружено файлов: {file ? Object.keys(file).length : 0} шт</div>
            </Form>
        </Formik>

    )
}
