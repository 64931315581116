import React        from 'react'
import { useLoad }  from '../hooks/request'
import DmsCheckItem from '../components/DmsCheckItem'
import {DMS_CHECK}  from '../urls'
import Main         from '../layouts/Main'

export default function DmsCheck({ match }) {
    const dmsCheck = useLoad({
      url:DMS_CHECK.replace('{code}', match.params.code)
    })

    return (
        <Main header={"Добровольное медицинское страхование"}>
            <DmsCheckItem key={dmsCheck.id} item={dmsCheck} code={match.params.code} />
        </Main>
    )
}
