import React            from 'react'
import logo             from '../static/img/dms-check/logo.svg'
import PhoneCheckCreate from './PhoneCheckCreate'

const divStyle = {
    'minHeight': '52vh',
    'display': 'flex',
    'alignItems': 'center',
    'justifyContent': 'center'
};

export default function PhoneCheckItem({ item: { response: item, loading }, code }) {
    if (loading && item === null)
        return (
            <div style={divStyle}>
                <div>Загрузка...</div>
            </div>
        )
    if (!loading && item === null)
        return (
            <div style={divStyle}>
                <div>Не найден</div>
            </div>
        )
    let img = <img src={item.photoPath} alt="" />
    if (!item.photoPath)
        img = (
            <a href="https://bima.tj/">
                <img width="200px" src={logo}></img>
            </a>
        )
    return (
        <div className="page">
            <div className="info">
                <div className="container info__container">
                    <h2 className="info__title visually-hidden">
                        Защита дисплея
                    </h2>
                    <div className="screen__info">
                        <div className="screen__information">
                            <span className="screen__title">Номер полиса:</span>
                            <span className="screen__data">{item.policyNumber}</span>
                        </div>
                        <div className="screen__information">
                            <span className="screen__title">Страхователь:</span>
                            <span className="screen__data">{item.lastName}&nbsp;{item.firstName}&nbsp;{item.patronymic}</span>
                        </div>
                        <div className="screen__information">
                            <span className="screen__title">Марка телефона:</span>
                            <span className="screen__data">{item.phoneMarka}</span>
                        </div>
                        <div className="screen__information">
                            <span className="screen__title">Модель телефона:</span>
                            <span className="screen__data">{item.phoneModel}</span>
                        </div>
                        <div className="screen__information">
                            <span className="screen__title">Период страхования:</span>
                            <span className="screen__data">{item.policyDateBegin} - {item.policyEndDate}</span>
                        </div>
                        <div className="screen__information">
                            <span className="screen__title">Сумма покрытия:</span>
                            <span className="screen__data">{item.sumAssured} сомони</span>
                        </div>
                        <div className="screen__information">
                            <span className="screen__title">Стоимость полиса:</span>
                            <span className="screen__data">{item.sumPrem} сомони</span>
                        </div>
                        
                    </div>
                    <PhoneCheckCreate qr_value={item.policyNumber} />
                </div>
            </div>
        </div>
    )
}
