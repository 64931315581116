import React        from 'react'
import { useLoad }  from '../hooks/request'
import PhoneCheckItem from '../components/PhoneCheckItem'
import {PHONE_CHECK}  from '../urls'
import Main         from '../layouts/Main'

export default function PhoneCheck({ match }) {
    const phoneInfo = useLoad({
      url:PHONE_CHECK.replace('{code}', match.params.code)
    })

    return (
        <Main header={"Защита экрана"}>
            <PhoneCheckItem key={phoneInfo.id} item={phoneInfo} code={match.params.code} />
        </Main>
    )
}
