import React  from 'react'
import Header from './parts/Header'
import Footer from './parts/Footer'

const Main = ({ children, header }) => (
  <div className='body__wrapper'>
    <Header header={header} />
    {children}
    <Footer />
  </div>
)

export default Main
