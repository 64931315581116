import React, { useEffect } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import NotFound from './pages/NotFound'
import BaseContextWrapper from './components/common/BaseContext'
import { getEnv } from './utils/environment'
import DmsCheck from "./pages/DmsCheck";
import PhoneCheck from "./pages/PhoneCheck";
import logo from './static/img/dms-check/logo.svg'

export default function App() {
    useEffect(() => {
        document.title = `ДОБРОВОЛЬНОЕ МЕДИЦИНСКОЕ СТРАХОВАНИЕ`
        document.querySelector("link[rel='shortcut icon']").href = logo
        if (getEnv() === 'production') return
        document.title += ` ${getEnv().toUpperCase()}`
        
    }, [])

    return (
        <BrowserRouter>
            <BaseContextWrapper>
                <Switch>
                    <Route path="/" component={DmsCheck} exact />
                    <Route path="/:code" component={DmsCheck} exact />
                    <Route path="/dms-check/:code" component={DmsCheck} exact />
                    <Route path="/phone/:code" component={PhoneCheck} exact />
                    <Route path="" component={NotFound} exact />
                </Switch>
            </BaseContextWrapper>
        </BrowserRouter>
    )
}
